import {AnyAction, CombinedState, combineReducers, configureStore, ThunkAction} from "@reduxjs/toolkit";
import advertisementsSlice, {advertisementsInterface} from "./features/advertisements/advertisements-slice";
import commentsSlice, {commentsInterface} from "./features/comments/comments-slice";
import gallerySlice, {imageGalleryInterface} from "./features/gallery/gallery-slice";
import serieContentsSlice, {serieContentsInterface} from "./features/serie-content/serie-content-slice";
import seriesSlice, {seriesInterface} from "./features/series/series-slice";
import userAuthSlice, {userAuthInterface} from "./features/user-auth/user-auth-slice";
import userDbSlice, {userDbInterface} from "./features/user-db/user-db-slice";
import videosSlice, {videosInterface} from "./features/videos/videos-slice";
import adContentSlice, {adContentsInterface} from "./features/content-ad/content-ad-slice";
import coinProductsSlice, {coinProductsInterface} from "./features/products/coin-products-slice";
import serieProductsSlice, {serieProductsInterface} from "./features/products/serie-products-slice";
import stickersSlice, {stickersInterface} from "./features/stickers/stickers-slice";
import notificationsSlice, {notificationsInterface} from "./features/notify/notify-slice";
import reportedCommentsSlice, {reportedCommentsInterface} from "./features/comments/reported-comments-slice";
import appStatusSlice, {appStatusInterface} from "./features/app-status/app-status-slice";
import dashboardSlice, {dashboardInterface} from "./features/dashboard/dashboard-slice";
import customerServiceSlice, {DBForms} from "./features/forms/customer-service-slice";
import marketingSlice, {marketingInterface} from "./features/marketing/marketing-slice";
import versionsSlice, {versionsInterface} from "./features/versions/versions-slice";
import crewSlice, {crewInterface} from "./features/crew/crew-slice";
import shrotsSlice, {shortsInterface} from "./features/shorts/shorts-slice";
import tagsSlice, {tagsInterface} from "./features/shorts/tags-slice";
import associatesSlice, {associatesResponseInterface} from "./features/associates/associates-slice";
import seriesRankingSlice, {seriesRankingInterface} from "./features/series/series-ranking-slice";
import contentRankingSlice, {contentsRankingInterface} from "./features/content-ad/content-ranking-slice";
import subscriptionSlice, {subscriptionInterface} from "./features/subscriptions/subscriptions-slice";
import bannersConfigurationSlice from "./features/banners-add-rotation/banners-configuration-slice";
import {ListPublicityRangeResponse} from "../data-access/banners/banners-config";
import serieRentalProductsSlice, {serieRentalProductsInterface} from "./features/products/rental-products-slice";
import {exclusiveRoomInterface} from "./features/exclusive-room/types";
import exclusiveRoomSlice from "./features/exclusive-room/exclusive-room-slice";
import programmedTaskSlice, {programmedTaskInterface} from "./features/programmed-task/programmed-task";
import dashboardPartnerSlice, {dashboardPartnerInterface} from "./features/dashboard-partner/dashboard-partner-slice";
import reportsPartnerSlice, {ReportsInterface} from "./features/reports/report-slice";
import rentalSlice from "./features/rentals/slice";
import type {RentalManagementState} from "./features/rentals/types";
import dashboardCorporateSlice, {dashboardCorporateInterface} from "./features/dashboard-corporate/dashboard-corporate-slice";
import {feedbackApi} from "./features/feedback/api";

const appReducers = combineReducers({
	appStatus: appStatusSlice.reducer,
	user: userAuthSlice.reducer,
	dbuser: userDbSlice.reducer,
	series: seriesSlice.reducer,
	seriesRanking: seriesRankingSlice.reducer,
	crew: crewSlice.reducer,
	serieContent: serieContentsSlice.reducer,
	videos: videosSlice.reducer,
	advertisements: advertisementsSlice.reducer,
	banners: bannersConfigurationSlice.reducer,
	comments: commentsSlice.reducer,
	reportedComments: reportedCommentsSlice.reducer,
	gallery: gallerySlice.reducer,
	adContent: adContentSlice.reducer,
	contentsRanking: contentRankingSlice.reducer,
	coinProducts: coinProductsSlice.reducer,
	serieProducts: serieProductsSlice.reducer,
	serieRentalProducts: serieRentalProductsSlice.reducer,
	stickers: stickersSlice.reducer,
	notifications: notificationsSlice.reducer,
	dashboard: dashboardSlice.reducer,
	dashboardPartner: dashboardPartnerSlice.reducer,
	dashboardCorporate: dashboardCorporateSlice.reducer,
	formData: customerServiceSlice.reducer,
	marketing: marketingSlice.reducer,
	versions: versionsSlice.reducer,
	shorts: shrotsSlice.reducer,
	tags: tagsSlice.reducer,
	associates: associatesSlice.reducer,
	subscription: subscriptionSlice.reducer,
	exclusiveRoom: exclusiveRoomSlice.reducer,
	programmedTask: programmedTaskSlice.reducer,
	reports: reportsPartnerSlice.reducer,
	rentals: rentalSlice.reducer,
	[feedbackApi.reducerPath]: feedbackApi.reducer,
});

type combinedStateType =
	| CombinedState<{
			appStatus: appStatusInterface;
			user: userAuthInterface;
			dbuser: userDbInterface;
			series: seriesInterface;
			seriesRanking: seriesRankingInterface;
			crew: crewInterface;
			videos: videosInterface;
			serieContent: serieContentsInterface;
			advertisements: advertisementsInterface;
			banners: ListPublicityRangeResponse;
			comments: commentsInterface;
			reportedComments: reportedCommentsInterface;
			gallery: imageGalleryInterface;
			adContent: adContentsInterface;
			contentsRanking: contentsRankingInterface;
			coinProducts: coinProductsInterface;
			serieProducts: serieProductsInterface;
			serieRentalProducts: serieRentalProductsInterface;
			stickers: stickersInterface;
			notifications: notificationsInterface;
			dashboard: dashboardInterface;
			dashboardPartner: dashboardPartnerInterface;
			dashboardCorporate: dashboardCorporateInterface;
			formData: DBForms;
			marketing: marketingInterface;
			versions: versionsInterface;
			shorts: shortsInterface;
			tags: tagsInterface;
			associates: associatesResponseInterface;
			subscription: subscriptionInterface;
			exclusiveRoom: exclusiveRoomInterface;
			programmedTask: programmedTaskInterface;
			reports: ReportsInterface;
			rentals: RentalManagementState;
			[feedbackApi.reducerPath]: ReturnType<typeof feedbackApi.reducer>;
	  }>
	| undefined;

const rootReducer = (state: combinedStateType, action: AnyAction) => {
	if (action.type === "reset") {
		return appReducers(undefined, action);
	}
	return appReducers(state, action);
};

const _store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			immutableCheck: {warnAfter: 128},
			serializableCheck: {warnAfter: 500},
		}).concat(feedbackApi.middleware),
});

// https://redux.js.org/usage/usage-with-typescript
export type RootState = ReturnType<typeof _store.getState>;
export type AppDispatch = typeof _store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>;

export default _store;
