import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {auth} from "../../../firebase";

const apiBaseUrl = process.env.REACT_APP_APP_DOMAIN;

export interface FeedbackListParams {
	searchby: string; // "reason" | "userid" | "feedback";
	searchvalue: string;
	pageIndex: number;
	pageSize: number;
	sortBy: string; // "created_at" | "feedback";
	sortOrder: string; // "asc" | "desc";
}

interface ListResponse<T> {
	currentPage: number;
	pageSize: number;
	totalResults: number;
	results: T[];
}

export interface Feedback {
	id: string;
	userid: string;
	feedback: number;
	reason: string;
	created_at: string;
	updated_at: string;
	next_feedback_allowed_at: string | null;
	name: string | null;
	email: string | null;
}

export const feedbackApi = createApi({
	reducerPath: "feedback",
	baseQuery: fetchBaseQuery({
		baseUrl: apiBaseUrl,
		async prepareHeaders(headers) {
			const token = await auth.currentUser?.getIdToken();
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: builder => ({
		getFeedbackList: builder.query<ListResponse<Feedback>, Partial<FeedbackListParams>>({
			query: params => ({
				url: "/admin_get_feedbacks_list",
				params: {
					...params,
					page: params.pageIndex,
					page_size: params.pageSize,
				},
			}),
			transformResponse: (response: any) => {
				return response;
			},
		}),
	}),
});

export const {useGetFeedbackListQuery, useLazyGetFeedbackListQuery} = feedbackApi;
